.menu .menu-logo {
  text-align: center;
  margin: 40px 30px 40px 20px;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
