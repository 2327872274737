.location-marker {
    width: 21px;
    height: 30px;
    background-image: url('/public/static/img/place_icon.png');
    background-size: cover;
    display: block;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 2;
}

.location-label {
    font-size: 16px;
    color: #fff;
}

.location-container {
    padding: 40px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}
