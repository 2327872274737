.municipality-panel {
  display: flex;
  flex-direction: column;
}

.municipality-paper {
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
}

.municipality-name-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.municipality-name {
  font-size: 1.5rem;
}

.panel-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 15px 5px 25px;
}

.panel-footer img {
  height: auto;
  max-height: 50px;
  margin-right: 10px;
}

.panel-footer.compensa img {
  width: 80px;
  min-width: 80px;
}

.panel-footer.proagro img {
  width: 50px;
  min-width: 50px;
}

.panel-footer.arx img {
  width: 50px;
  min-width: 50px;
}

.panel-footer .panel-info-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.panel-footer .panel-info {
  font-size: 10px;
  color: #515151;
  opacity: 0.5;
}

.hidden {
  display: none;
  visibility: hidden;
}
