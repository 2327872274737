.auth-container {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  justify-content: flex-end;
  background: #d9d9d9;
}

.auth-container .panel {
  flex: 0 1 480px;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px 0 rgba(0,0,0,0.27);
}

.auth-container .panel .logo {
  flex: 1;
}

.auth-container .panel .content,
.auth-container .panel .placeholder {
  flex: 2;
}

.auth-container .panel .placeholder {
  display: flex;
  justify-content: flex-end; flex-direction: column;
}

.auth-container .panel .logo {
  padding-top: 55px;
}

.auth-container .auth-background {
  flex: 1 1 0%;
  background: white no-repeat;
  background-position-x:  right;
  background-position-y: bottom;
  background-size: cover;
  display: flex;
  justify-content: center;
  padding-top: 70px;
}

@media (max-width: 480px) {
  .auth-container .auth-background {
    display: none;
  }
}
