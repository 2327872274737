.legend {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.legend-palette {
  display: flex;
  justify-content: center;
}

.legend .legend-block {
  display: inline-block;
  height: 12px;
  flex: 2;
  font-size: 8px;
}

.legend .unit-block {
  flex: 1;
  padding-left: 5px;
}

.legend .legend-label {
  position: relative;
  top: -10px;
  float: right;
}

.legend .unit-wrapper {
  position: relative;
  padding-right: 2px;
}

.legend .unit {
  font-size: 9px;
  position: absolute;
  bottom: 12px;
  right: -17px;
}

.legend .legend-pictures {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.legend .legend-pictures div {
  transform: rotate(90deg);
}
