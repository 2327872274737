.search {
  position: relative;
}

.search-row {
  display: flex;
  align-items: center;
  position: relative;
}

.search-icon {
  margin: 0 16px;
  cursor: pointer;
}

.search-input {
  flex: 1;
  position: relative;
}

.search-icon-location {
  margin-left: 18px;
  padding-left: 16px;
  border-left: 1px solid #ddd;
}

@media (min-width: 480px) {
  .search {
    width: 360px;
  }
}
