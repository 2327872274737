.controls-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
}

.desktop-controls-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
}

.controls {
  z-index: 1;
  right: 0;
  bottom: 0;
  width: 480px;
}

.mobile .controls {
  width: 100%;
}

.controls .control-legend-expanded {
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.desktop-controls-wrapper .controls {
  box-shadow:
    rgba(0, 0, 0, 0.14) 0 8px 10px 1px,
    rgba(0, 0, 0, 0.12) 0 3px 14px 2px,
    rgba(0, 0, 0, 0.2) 0 5px 5px -3px;
}
