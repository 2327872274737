.side-panel-controls {
  display: flex;
  justify-content: space-between;
  margin: 15px 5px 0 10px;
  height: 40px;
  min-height: 40px;
  box-sizing: border-box;
  align-items: center;
}

.photos-list-sync-toggle {
  width: calc(100% - 85px) !important;
}

.side-panel-controls.feature-list-panel {
  margin: 8px 10px;
}

.with-padding {
  padding: 16px 8px 0;
}

