.icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.5rem -1.5rem 0;
  transition: all 650ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.icons.icons-bottom {
  margin-bottom: 10px;
}

.icons .icon-button {
  margin-bottom: 10px;
  z-index: 100;
}

.icons .mini-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 0;
  overflow: hidden;
  transition: all 650ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  margin-bottom: -30px;
  padding-bottom: 30px;
}

.icons .mini-icons-expanded {
  max-height: 250px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}


.icons .layers-button {
  z-index: 200;
}
