.loader-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

.map-mode-button,
.list-mode-button {
  position: absolute;
  bottom: 32px;
  right: 8px;
  z-index: 1302;
}

.mobile .user-profile button {
  background-color: rgb(239, 239, 239) !important;
}

.mobile .user-profile button div {
  color: #2196F3 !important;
}
