.date-picker {
  font-size: 1.1em;
  height: 75px;
  position: relative;
  overflow-y: hidden;
  cursor: move;
}

.date-picker-scale {
  white-space: nowrap;
  overflow: hidden;
  height: 50px;
  padding-top: 27px;
  cursor: grab;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

.date-picker-scale:active:hover {
  cursor: grabbing;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}

.date-picker-month-wrapper {
  display: inline-block;
  position: relative;
}

.date-picker-month {
  display: inline-block;
  border-left: 1px solid #000;
  height: 50px;
  overflow: hidden;
}

.date-picker-scale-padding {
  display: inline-block;
  width: 50%;
}

.date-picker-month-label {
  position: absolute;
  left: -1.05em;
  background: #fff;
  padding: 0.3em;
}

.date-picker-year-label {
  position: absolute;
  top: -0.15em;
  margin-left: 0.1em;
  font-size: 0.8em;
}

.date-picker-tick {
  margin-top: 40px;
  height: 10px;
  border-right: 1px solid #000;
  display: inline-block;
}

.date-picker-pointer {
  border: 2px solid rgb(0, 153, 204);
  width: 0;
  position: absolute;
  height: 50%;
  top: 50%;
  left: 50%;
  margin-left: -1px;
}

.date-picker-selected-date {
  font-size: 0.8em;
  width: 72px;
  height: 20px;
  padding: 0 2px;
  position: absolute;
  top: 0;
  left: calc(50% - 36px);
}
