.ndvi-controls {
  display: flex;
  align-items: flex-end;
  height: 100%;
  position: relative;
}

.ndvi-controls .map-loader {
  position: absolute;
  z-index: 100;
  top: 5px;
  left: 5px;
}

.ndvi-legend-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #d9d9d9;
}

.ndvi-legend-wrapper .legend {
  width: 90%;
  border: none;
  padding-right: 5px;
}

.ndvi-legend-wrapper .info-btn {
  margin: 0 10px 25px 5px;
  cursor: pointer;
}
