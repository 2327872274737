.mapboxgl-ctrl-geocoder--pin-right, .mapboxgl-ctrl-geocoder--icon-search {
    display: none;
}

.mapboxgl-ctrl-geocoder--input {
    width: 229px;
    height: 48px;
    border: none;
    line-height: 24px;
    font-size: 15px;
}

.mapboxgl-ctrl-geocoder--input:focus {
    outline: none;
}

.suggestions-wrapper {
    width: 250px;
    background-color: rgba(255, 255, 255, 0.986);
    position: absolute;
    top: 55px;
    z-index: 2;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.suggestions-wrapper ul {
    padding: 0;
}

.suggestions-wrapper ul li {
    padding: 5px 20px;
    list-style-type: none;
}

.suggestions-wrapper ul li:hover {
    background-color: rgba(0, 0, 0, 0.103);
    transition: background-color 300ms linear;
    cursor: pointer;
}

