@media (min-width: 768px) {
  .municipality-panel,
  .territories-panel {
    min-width: 670px;
    max-width: 670px;
  }

  .municipality-panel.mobile ,
  .territories-panel.mobile {
    max-width: 100%;
  }
}

@media (min-width: 1024px) {
  .territories-panel header.mobile .header-controls {
    display: flex;
    justify-content: space-between;
    margin-right: 16px;
    width: 100%;
  }
}

.territories-panel {
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
}

.territories-paper {
  width: 100%;
  padding: 15px;
  display: block;
}

.territory-list-wrapper {
  overflow-y: auto;
  font-size: 12px;
  flex: 1 1 90%;
}

.territories-panel.mobile .territory-list-wrapper {
  font-size: 10px;
}

.overview-controls {
  margin: 0 20px;
}

.search.territory-search {
  position: relative;
  margin: 0 0 20px 0;
  width: 100%;
}

.territories-panel section {
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  box-sizing: border-box;
  border-bottom: 1px solid #D9D9D9;
  flex: 1 1;
}

.territories-panel section header.desktop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.territories-panel section header.mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

header .float-left,
header .float-right {
  display: flex;
  align-items: center;
}

header .float-right > span {
  margin: 0 16px;
  font-size: 12px;
}

.season-btn {
  max-width: 125px;
  color: #717171 !important;
}

.season-btn.active {
  color: #4285F4 !important;
}

.product-controls {
  margin: 10px 20px 5px;
  height: 40px;
  min-height: 40px;
  box-sizing: border-box;
}
.product-controls .product-bar * {
  border-radius: 0 !important;
}

.product-bar > div.btn.product,
.product-bar > div.btn.product:first-child,
.product-bar > div.btn.product:first-child button,
.product-bar > div.btn.product:first-child button > div > div {
  border-radius: 4px 0 0 4px !important;
}

.product-bar > div.btn.product,
.product-bar > div.btn.product:last-child,
.product-bar > div.btn.product:last-child button,
.product-bar > div.btn.product:last-child button > div > div{
  border-radius: 0 4px 4px 0 !important;
}

.product-controls .export-btn {
  cursor: pointer;
  padding: 0;
}

.product-controls .btn {
  width: 140px;
  white-space: nowrap;
}

.product-controls .btn.active > button > div > div{
  background-color: #e8effd;
}

.search-field {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 30px;
  width: 200px;
  border: 1px solid rgba(0, 0, 0, 0.32);
  border-radius: 4px;
}

.search-field .search-icon {
  margin: 8px;
}

.side-panel-controls.search-bar {
  margin-top: 20px;
  margin-bottom: 12px;
}

section.mobile .side-panel-controls.search-bar {
  margin: 0 20px;
}

.territories-panel header.desktop .header-controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
}


