.map {
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
}

.map.mobile {
  display: flex;
  position: relative;
  flex: 1 1 100%;
}

/*Hide the gray bar appearing on the right side*/
.gm-style-cc {
  visibility: hidden;
}

.mapboxgl-ctrl {
  margin: 0 !important;
}

.mapboxgl-ctrl .mapboxgl-ctrl-logo {
  margin: 16px !important;
}

.mobile .mapboxgl-ctrl .mapboxgl-ctrl-logo {
  margin: 0 !important;
}

.mapboxgl-ctrl-zoom-in,
.mapboxgl-ctrl-zoom-out,
.mapboxgl-ctrl-geolocate {
  width: 40px !important;
  height: 40px !important;
}

.user-profile,
.mapboxgl-ctrl-group,
.gm-bundled-control > div:first-child {
  box-shadow: rgba(0, 0, 0, 0.14) 0 8px 10px 1px,
  rgba(0, 0, 0, 0.12) 0 3px 14px 2px,
  rgba(0, 0, 0, 0.2) 0 5px 5px -3px !important;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl:not(:first-child),
.gm-bundled-control {
  margin-top: 16px !important;
  margin-right: 16px !important;
}

.gm-bundled-control-on-bottom {
  margin-bottom: 16px !important;
}

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl:not(.mapboxgl-ctrl-attrib) {
  margin-bottom: 16px !important;
  margin-right: 16px !important;
}

.map.mobile .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl:not(.mapboxgl-ctrl-attrib) {
  margin-bottom: 88px !important;
}

.mapboxgl-control-container .mapboxgl-ctrl-bottom-left {
  z-index: 10;
}

.soil-moisture-palette {
  background: url('/public/static/img/soil-moisture-palette.jpeg') no-repeat;
  background-size: 410px;
}

.with-box-shadow {
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.14) 0 8px 10px 1px,
  rgba(0, 0, 0, 0.12) 0 3px 14px 2px,
  rgba(0, 0, 0, 0.2) 0 5px 5px -3px;
}
