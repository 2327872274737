.precip-controls {
  transition: all 650ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.precip-controls .controls {
  padding-top: 5px;
  padding-bottom: 15px;
}

.precip-controls .control-header {
  display: flex;
  align-items: center;
  padding: 5px 5px 0 5px;
  height: 30px;
}

.precip-controls .control-header button {
  box-shadow: none;
}

.precip-controls .control-header .precip-header {
  flex: 1;
}

.precip-controls .control-header > .precip-header {
  flex-basis: 70%;
}

.precip-controls .control-header > .control-icons {
  display: flex;
  flex-basis: 15%;
}
