.splash {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20000;
  justify-content: center;
}

.splash .logos {
  z-index: 20001;
  max-width: 480px;
  width: 100%;
  height: 760px;
  background-size: 480px;
}

.splash,
.splash .logos {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 24px 48px 0 rgba(0,0,0,0.5);
  border-radius:4px;
}

.splash-fade {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.splash .opti-crop-logo {
  height: 25px;
  background-size: 260px 25px;
  margin-top: 40px;
}

.splash .logos .brand-logo-container {
  width: 250px;
  max-height: 250px;
  margin-top: 40px;
  text-align: center;
  height:140px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.brand-logo-container img {
  height: auto;
  max-height:140px;
  width: auto;
  max-width:240px;
  margin: auto;
}

.splash .logos .brand-logo-container.proagro-logo {
  margin-top: 20px;
}

.splash-background {
  display: none;
}

/*mobile devices*/
@media (max-width: 320px) {
  .splash-background {
    display: none;
  }
}

@media (max-width: 480px) {
  .splash-background {
    display: none;
  }
  .splash .logos {
    width:100%;
    height:100%;
    background-size:cover;
    border-radius:0;
  }
}

/*desktops*/
@media (min-width: 480px) {
  .splash-background {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 19999;
    opacity: 0.6;
    background-color: black;
  }

  .splash .logos {
    height: 760px;
  }
}
