.error {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error .brand-logo {
  margin: 3% 0;
  flex: 1;
  max-width: 250px;
}

.error .app-logo-container {
  flex: 1;
}

.error .content {
  flex: 4;
}

.error .content div {
 text-align: center;
}

.error p {
  max-width: 500px;
  text-align: center;
  font-size: 1.3em;
  line-height: 1.6em;
}
