.territory-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  cursor: pointer;
}

.territory-mark {
  width: 3%;
  text-align: center;
  opacity: 0.75;
  border-radius: 4px 0 0 4px;
}

.territory-mark span {
  font-weight: 900;
  color: #fff;
  position: relative;
  top: 45%;
}

.territory-info-wrapper {
  width: 97%;
  padding: 7px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.intermediate-periods-item .territory-info-wrapper {
  padding: 10px;
}

.territory-main {
  width: 30%;
}

.intermediate-periods-item .territory-main {
  width: 15%;
}

.territory-indicators {
  width: 70%;
  display: flex;
}

.intermediate-periods-item .territory-indicators {
  width: 85%;
}

.intermediate-periods-item .territory-indicators {
  width: 85%;
}

.intermediate-periods-item .territory-indicators .territory-info-block {
  width: 20%;
}

.intermediate-periods-item .territory-indicators .territory-info-block.territory-status-block {
  width: 30%;
}

.territory-info-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  border-left: 1px solid #D9D9D9;
  padding: 0 15px;
}

.intermediate-periods-item .territory-info-block {
  padding: 0 10px;
}

.intermediate-periods-item .territory-info-block.territory-status-block {
  width: 25%;
}

.territory-info-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3px 0;
}

.color-grey {
  color: #717171;
}
