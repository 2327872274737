.app-logo {
  width: 260px;
  height: 45px;
  background-image: url('/public/static/img/opti_crop_logo.svg');
  background-size: 260px 45px;
}

@media (max-width: 480px) {
  .app-logo {
    width: 250px;
    height: 44px;
    background-size: 250px 44px;
  }
}
