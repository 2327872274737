@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

html, body, #root,
#root > div:first-child {
  height: 100%;
  display: flex;
  max-width: 100%;
  flex: 1 1 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.ndvi-low {
  background: url('/public/static/img/ndvi_low.svg') no-repeat;
}

.ndvi-middle {
  background: url('/public/static/img/ndvi_middle.svg') no-repeat;
}

.ndvi-high {
  background: url('/public/static/img/ndvi_high.svg') no-repeat;
}

.axis-icon {
  width: 30px;
  height: 30px;
}

.hidden {
  visibility: hidden;
}

/* Hide clear button on IE */
input[type=text]::-ms-clear {
  display: none;
}
