.feature-list-item-layout-column.margin {
  margin: 0 0 0 5px;
}

.feature-list-item-text-field-label-fix label {
  color: rgba(0, 0, 0, 0.54) !important;
  transform: none !important;
  font-size: 12px !important;
  top: 0 !important;
}

.feature-list-item-text-field-label-fix input {
  color: rgba(0, 0, 0, 0.87) !important;
  margin-top: 20px !important;
  font-size: 13px !important;
  height: auto !important;
}

.feature-list-item-text-field-label-fix hr
{
  bottom: 0 !important;
}
