.contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contacts .logo {
  margin-top: 35px;
  max-width: 150px;
}
