.mapboxgl-ctrl-bottom-right {
    z-index: 3;
}

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib {
  font-size: 10px;
}
.controls-bottom {
    width: 480px;
    height: 21px;
    background-color: #fff;
    z-index: 2;
}
