.soil-moisture-controls {
  display: flex;
  align-items: flex-end;
  height: 100%;
  position: relative;
}

.soil-moisture-controls .map-loader {
  position: absolute;
  z-index: 100;
  top: 5px;
  left: 5px;
}

.soil-moisture-legend h5 {
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  margin: 5px 0 0;
}
