.feature-upload-result {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  font-size: 12px;
}

.feature-upload-result .result-content {
  flex: 1;
  max-height: 600px;
  overflow: auto;
}
