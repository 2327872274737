.feature-list-item {
  margin: 10px;
  height: 95px;
}

.feature-list-item.mobile {
  width: 100%;
  height: 80px;
  margin: 0;
}

.feature-list-item-layout {
  display: flex;
  flex-direction: row;
}

.mobile .feature-list-item-layout {
  justify-content: space-between;
}

.feature-list-item-layout-column {
  display: flex;
  flex-direction: column;
}

.feature-list-item-layout-column.v-center {
  justify-content: center;
}

.feature-list-item-layout-column.grow {
  flex-grow: 2;
}

.feature-list-item-layout-column.tooling {
  flex-grow: 1;
  padding: 0;
}

@media all and (max-width: 480px) {
  .feature-list-item-layout-column.tooling {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .feature-list-item {
    width: 100%;
    height: 80px;
    margin: 0;
  }
}
