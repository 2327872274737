.municipality-graphs {
  padding: 15px 15px 0 15px;
}

.municipality-graphs .municipality-graph,
.municipality-graphs .accumulative-graph {
  min-height: 350px;
}

.municipality-graphs .municipality-graph {
  max-height: 350px;
}

.municipality-graphs .cumulative-graph {
  max-height: 400px;
}

.municipality-graphs .cumulative-graph {
  margin-top: -25px;
}

.graph-loader.municipality-loader {
  top: 50%;
  left: 50%;
  margin-left: -50px;
  position: absolute !important;
}

.highcharts-plot-line-label {
  color: #515151;
  opacity: 0.4;
}

.highcharts-legend-item > text {
  fill: rgba(0, 0, 0, 0.6) !important;
}

.highcharts-trigger-series .highcharts-area {
  fill-opacity: 0.12;
}

.highcharts-trigger-series .highcharts-graph {
  stroke: none;
}

.highcharts-trigger-series:hover .highcharts-area{
  fill-opacity: 0.22;
}
