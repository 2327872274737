.feature-upload-summary {
  display: flex;
  justify-content: left;
  flex-direction: row;
  margin: 5px 0;
}

.feature-upload-summary .icon {
  display: flex;
  padding: 3px 6px 3px 3px;
}

.feature-upload-summary .icon-placeholder {
  display: flex;
  padding: 3px 6px 3px 40px;
}

.feature-upload-summary .message {
  display: flex;
  align-items: center;
  padding: 3px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
}
