.overlay {
  position: absolute;
  background: #000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  opacity: 0.4;
}
